import React, { Component } from "react"
import Header from './header/Header'
import ISI from './isi/ISI'
import Footer from './footer/Footer'
import _ from 'underscore'
import Modal from './modal/Modal'
import HomeModal from '../components/modal/HomeModal'


import { Waypoint } from 'react-waypoint';

export default class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: false,
      hideNav : false,
      modal: 'null',
      prevScrollpos: 0,
      cookieSet: false,
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
        let bool = window.matchMedia("only screen and (max-width: 1023px)").matches;
        this.setState({
          isMobile: bool,
        })
    }
    document.addEventListener("keydown", this.screenshotMode, false);
  }

  componentWillUnmount(){

  }

  //add screenshot mode to the body for screenshot purpose only
  screenshotMode = (e) => {
    if(e.ctrlKey && e.key === "l") {
      const element = document.querySelector('body');
      element.classList.toggle('screenshot-mode');
    }
  }


  goModal = (targ) => {
     this.setState(() => ({ modal: targ }))
  }

  modalClosed = () => {
    this.setState(() => ({ modal: null }))
  }

  



  render() {

    const children = this.props.children;
    // const c = this.getRefs();

    return (
      <>
        <HomeModal/>
        <Modal modal={this.state.modal} modalClosed={this.modalClosed}/>
        <Header isMobile={this.state.isMobile} hideNav={this.state.hideNav} goModal={this.goModal}/>
          <div className="grad"></div>
          <div className="wrapper">
            <div className="container">
              {children}
            </div>
          </div>
      </>
    )
  }
}
