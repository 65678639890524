import React from "react"
import './form.css'
import { Link } from "gatsby"
import $ from "jquery"


export default class RequestForm extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    specialty: "",
    city: "",
    state: "",
    zipCode: "",
    npiNumber: "",
    licenseNumber: "",
    licenseState: "",
    validateFName: true,
    validateLName: true,
    validateEmail: true,
    validatePhone: true,
    validateSpecialty: true,
    validateCity: true,
    validateState: true,
    validateAddress: true,
    validateZipCode: true,
    validateNPI: true,
    validateLicense: true,
    validateLicenseState: true,
    samples: false,
    hcp: false,
    validateHCP: true,
    valid: true,

  }

  stateArray = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
]
  
  toggleValidation = event =>{
    this.state.hcp ? this.setState({hcp:false}) :  this.setState({hcp: true})
  }

  toggleSamples = event =>{
    this.state.samples? this.setState({samples:false}) : this.setState({samples: true})
  }

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSelectChange = event => {
    let {name, value} = event.target;
    this.setState({
      [name]: value
    })
  }

  // method to check if the input is a valid name
  validateName = (name, event, errorDiv) => {
    let errorText = document.querySelector(`#${errorDiv}`);
    if(name.length < 2){
      if(errorDiv === "FNameError"){
        errorText.innerHTML = "Please enter a first name"
      }else{
        errorText.innerHTML = "Please enter a last name"
      }
      return false;
      event.preventDefault()

    }
    else if( (/[0-9!@#\$%^*)(+=_?\[\]{}<>|]+|http|www|\.com|\.edu|\.tech|\.net|\.gov/).test(name) ) {
      errorText.innerHTML = `Special characters like [!, @, #, $, ^] are not allowed. Please enter a valid name using letters and/or the following special characters: , ., \, /, &`
      return false;
      event.preventDefault()

    }
    return true
  }

  validateEmail = (event) => {
    let errorText = document.querySelector('#emailError')
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(this.state.email))
    {
      return (true)
    }
    else if (!(/^.*@*(\.\w{2,5})+$/).test(this.state.email))
    {
      errorText.innerHTML = 'This email address is missing a top-level domain (such as ".com")'
    }
    else if(!(this.state.email).includes('@')){
      errorText.innerHTML = "This email address is missing `@`."
    }
    else{
      errorText.innerHTML = "Please enter a valid email"
    }
    event.preventDefault()
    return (false)
  }
 

  handleSubmit = (event) => {
    this.setState({validateFName: this.validateName(this.state.firstName, event, "FNameError")});
    this.setState({validateLName: this.validateName(this.state.lastName, event, "LNameError")});
    this.setState({validateEmail: this.validateEmail(event)}) 
    this.validatePhone() ? this.setState({validatePhone: true}) : this.setState({validatePhone:false},event.preventDefault())
    this.state.specialty.length > 0 ? this.setState({validateSpecialty: true}) : this.setState({validateSpecialty: false},event.preventDefault())
    this.state.city.length >= 2 ? this.setState({validateCity:true}) : this.setState({validateCity: false},event.preventDefault())
    this.state.address.length  >= 2 ? this.setState({validateAddress:true}) : this.setState({validateAddress: false},event.preventDefault())
    this.state.state.length  === 2 ? this.setState({validateState:true}) : this.setState({validateState: false},event.preventDefault())
    this.state.zipCode.length  === 5 || this.state.zipCode.length  === 9 ? this.setState({validateZipCode:true}) : this.setState({validateZipCode: false},event.preventDefault())
    this.state.npiNumber.length  === 10 ? this.setState({validateNPI:true}) : this.setState({validateNPI: false},event.preventDefault())
    this.state.licenseNumber.length  >= 2 ? this.setState({validateLicense:true}) : this.setState({validateLicense: false},event.preventDefault())
    this.state.licenseState.length  >= 2 ? this.setState({validateLicenseState:true}) : this.setState({validateLicenseState: false},event.preventDefault())
    if(this.state.hcp === true){
    this.setState({validateHCP:true})
    this.submitSignUpForm(
      function (err, res) {
      if (err) {
        // console.log(err);
      } else {
          // console.log(res);
      }
      }
    )
    }
    else{
      this.setState({validateHCP:false},event.preventDefault())
    }


  }

  validatePhone = () => {
    var phoneno = /^\d{10}$/;
    if(this.state.phoneNumber.match(phoneno)){
            return true;
          }
        else{
            return false;
          }
        }

    submitSignUpForm = (callback) => {
       var payload = {
         firstName: this.state.firstName,
         lastName: this.state.lastName,
         email: this.state.email,
         phone: this.state.phoneNumber,
         address: this.state.address ,
         city: this.state.city,
         state: this.state.state,
         zip: this.state.zipCode,
         specialty: this.state.specialty,
         npi: this.state.npiNumber,
         licenseNumber: this.state.licenseNumber,
         licenseState: this.state.licenseState,
         is_requesting_rep_visit: "true",
         is_requesting_samples: this.state.samples,
         is_opting_in: this.state.hcp,
         source:  "4",
       }

       $.ajax({
         url: "/phpSide/",
         data: payload,
         type: "POST",
         crossDomain: true,
         complete: function (resp) {
           // console.log('complete')
           callback(null, resp);
         },
         success: function(resp){
           /*if(resp.status == 200) {
             console.log("form submitted");
             console.log(resp)
           }
           else if (resp.status == 500) {
             console.log('err')
             console.log(resp)
           }
           else{
             console.log("else-triggered")
             console.log(resp)
           }*/
         },
         error: function(resp) {
           // console.log(resp)
           if(resp.status == 200) {
             // console.log("form submitted");
             callback(null, resp);
           }
           else if (resp.status == 500) {
             // console.log('err')
             callback(resp)
           }
         }
       })
     }





  render() {
    return (
      	<div style={{ maxWidth: "600px", marginBottom: "30px"}}>
			  	<h2 className="fw-300">Enter your information to get in contact with an Amarin sales rep</h2>
          <em>
            <span>*Indicates required field</span>
          </em>

          <form  className="signup rep-request-form">
            <div className="two-sections m-y-20">
              <label>
                *First name

                <br></br>
                <input
                  type="text"
                  name="firstName"
                  value={this.state.firstName}
                  onChange={this.handleInputChange}
                />
              {this.state.validateFName ? <div className="validation" id="FNameError"></div> : <div className="validation" id="FNameError"> Please enter a first name </div>}
              </label>

              <label className="m-l-40">
                *Last name

                <br></br>
                <input
                  type="text"
                  name="lastName"
                  value={this.state.lastName}
                  onChange={this.handleInputChange}
                />
              {this.state.validateLName? <div className="validation" id="LNameError"></div> : <div className="validation" id="LNameError"> Please enter a last name </div>}
              </label>
            </div>
            <div className="two-sections m-y-20">
              <label>
                *Email

                <br></br>
                <input
                  type="text"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                />
              <div className="validation" id="emailError"></div>
              </label>
              <label className="m-l-40">
                *Phone number

                <br></br>
                <input
                  type="text"
                  name="phoneNumber"
                  value={this.state.phoneNumber}
                  onChange={this.handleInputChange}
                />
              {this.state.validatePhone ? "" : <div className="validation"> Please enter a valid phone number </div>}
              </label>
            </div>
            <label className="full-width-input">
              *Street address

              <br />
              <input
                type="text"
                name="address"
                value={this.state.address}
                onChange={this.handleInputChange}
              />
            {this.state.validateAddress ? "" :  <div className="validation"> Please enter a street address </div>}
            </label>
            <div className="two-sections m-y-20">
              <label>
                *City

                <br />
                <input
                  type="text"
                  name="city"
                  value={this.state.city}
                  onChange={this.handleInputChange}
                />
              {this.state.validateCity ? "" :  <div className="validation"> Please enter a city </div>}
              </label>
              <label className="m-l-40">
                *State

                <br />
              <select className="half-width-select" name="state" onChange={this.handleSelectChange}>
                <option value="">State</option>
                {this.stateArray.map(item => <option key={item} value={item}>{item}</option>)}
              </select>
              {this.state.validateState ? "" : <div className="validation"> Please select a state from the dropdown above </div>}
              </label>
            </div>
            <div className="m-y-20">
            <label className="">
              *ZIP code

              <br></br>
              <input
                type="text"
                name="zipCode"
                value={this.state.zipCode}
                onChange={this.handleInputChange}
              />
            {this.state.validateZipCode ? "" : <div className="validation"> Please enter a valid 5- or 9-digit ZIP code</div>}
            </label>
            </div>



            <label>
              *Specialty

              <br></br>
              <select name="specialty" onChange={this.handleSelectChange} >
                <option value="">
                  {" "}
                  Select one
                </option>
                <option value="cardiology">Cardiology</option>
                <option value="endocrinology">Endocrinology</option>
                <option value="familyPractice">Family Practice</option>
                <option value="generalPractice">General Practice</option>
                <option value="internalMedicine">Internal Medicine</option>
                <option value="nursePractitioner">Nurse Practitioner</option>
                <option value="pharmacist">Pharmacist</option>
                <option value="physicianAssistant">Physician Assistant</option>
                <option value="other">Other</option>
              </select>
              {this.state.validateSpecialty ? "" : <div className="validation"> Please select a specialty from the dropdown above </div>}
            </label>


            <div className="two-sections m-y-20">
              <label>
                *NPI number

                <br></br>
                <input
                  type="text"
                  name="npiNumber"
                  value={this.state.npiNumber}
                  onChange={this.handleInputChange}
                />
              {this.state.validateNPI? "" : <div className="validation"> Please enter a valid 10-digit NPI number </div>}
              </label>
              <label className="m-l-40">
                *License number

                <br></br>
                <input
                  type="text"
                  name="licenseNumber"
                  value={this.state.licenseNumber}
                  onChange={this.handleInputChange}
                />
              {this.state.validateLicense? "" : <div className="validation"> Please enter a license number </div>}
              </label>
            </div>

          	<label className="">
              *State of license

              <br></br>
                <select
                  name="stateOfLicense"
                  name="licenseState"
                  value={this.state.licenseState}
                  onChange={this.handleInputChange}
                  >
                <option value="">State</option>
                {this.stateArray.map(item => <option key={item} value={item}>{item}</option>)}
                </select>
                {this.state.validateLicenseState ? "" :  <div className="validation"> Please select a state from the dropdown above </div>}
            </label>
            <div>
              <label style={{marginBottom:"0px"}} className="label-req">
               <br/>
                <input className="hcpCheckbox" onClick={this.toggleSamples} type="checkbox" />
                <span className="p-l-5 m-p-l-16">I would like to request samples</span>
              </label>
            </div>

            <div style={{maxWidth:"550px"}}>
              <label className="label-req">
               <br/>
                <input className="hcpCheckbox" onClick={this.toggleValidation}  style={{position:"absolute", marginTop:"6px"}} type="checkbox" />
                <span style={{fontWeight:"300",paddingLeft:"18px",display:"block"}} className="p-l-5 m-p-l-30">I certify that I am a Healthcare Professional and want to receive information from Amarin, and third parties working on behalf of Amarin. I agree that Amarin may use the information I provide to send me health-related materials, as well as information on Amarin services, including marketing programs. I understand that I can opt out at any time by clicking the “Unsubscribe” at the bottom of all emails received.
                </span>
                {this.state.validateHCP ? "" :  <div style={{fontWeight:"300",paddingLeft:"0px"}} className="validation">Please certify your understanding of the above information</div>}
              </label>
            </div>



            <p style={{paddingLeft:"0px"}}>Your registration is subject to Amarin's <a href="https://amarincorp.com/privacy_policy.html" target="_blank">Privacy Policy</a>.</p>
						<Link style={{textDecoration:"none"}}  onClick={this.handleSubmit}  to="register-thank-you"><button type="submit" value="Submit" className="homeBtn mobile-align-center">Submit</button></Link>
					</form>
      	</div>

    )
  }
}
