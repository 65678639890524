import Layout from "../components/Layout"
import RequestFrom from "../components/form/requestform"
import "../components/form/form.css"
import Hero from '../components/hero/Hero'
import React, { Component } from "react"

import { Helmet } from "react-helmet"
import ISI from '../components/isi/ISI'
import { Waypoint } from 'react-waypoint';
import Footer from '../components/footer/Footer'
import SEO from "../components/seo"

import Loader from "../components/loader"


export default class Request extends Component {

    constructor(props) {
        super(props)
        this.state = {
            form: 'requestform',
        }
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick (event) {
        // console.log(event.target.id)
        this.setState({
          form: event.target.id,
        });
    }

   componentDidMount() {
        this.setState({form: 'requestform'})
        // document.querySelector('#requestform').checked = "true";
    }

    render() {
        return (
            <>
            <Loader>
            <Layout>
              <SEO
                title="Request Rep Visit"
                keywords={[""]}
                description="Get in contact with a rep to learn more about VASCEPA."/>
                <Hero bckgrdImage={'landingimage'}>
                    <h1 className="headline">Register now for samples and to stay informed</h1>
                </Hero>

                <div className="vascepa-register">
                    <div className="endpoints" >
                        <div className="right-options">
                            <RequestFrom/>
                        </div>
                    </div>
                </div>
            </Layout>
            <ISI disclamer="Visual representations are for illustrative purposes only. The capsule shown is not an identical representation of the product."/>
            <Waypoint
             onEnter={this.handleFooterEnter}
             onLeave={this.handleFooterLeave}/>
            <Footer />
            </Loader>
            </>

        )
    }
}
